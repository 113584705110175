import React, { useContext } from "react";
import LanguageContext from "./../store/LanguageContext";
import classes from '../styles/LanguageSwitcher.module.css'

const LanguageSwitcher = () => {
    const { language, setLanguage } = useContext(LanguageContext);
    return (
        <div className='dropdown'>
            <button className={` dropdown-toggle ${classes.languageButton}`} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {language}
            </button>
            <div className={`dropdown-menu ${classes.languageMenu}`} aria-labelledby="dropdownMenuButton">
                <button className="dropdown-item" onClick={() =>setLanguage("NL")}>NL</button>
                <button className="dropdown-item" onClick={() => setLanguage("FR")}>FR</button>
            </div>
        </div> 

    );
  };

export default LanguageSwitcher;