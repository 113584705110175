import React, {useContext, useState} from 'react'
import classes from '../styles/Footer.module.css'
import LanguageContext from '../store/LanguageContext';

import {useLocation, useNavigate} from 'react-router-dom';

const SearchForm = (props) => {
    const location = useLocation();
    const navigate = useNavigate();


    const ctx = useContext(LanguageContext);
    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    function handleSubmit(e) {
        e.preventDefault();
        if (searchTerm.trim() !== "")
        {
            //setSearchTerm("");
            navigate(`/search`,{ state: { query: searchTerm.trim()} }); 
        }
      }

    //============
    const searchFR = "Recherche...";//Search
    const searchNL = "Zoeken...";
    //==============================================================
    //<form className="nosubmit hidden-mobile"  onSubmit={handleSubmit}>
    return (
        <form className="nosubmit"  onSubmit={handleSubmit}>
            <input 
                className="nosubmit" 
                type="search" 
                placeholder={ctx.language === 'FR' ? searchFR : searchNL}
                value={searchTerm}
                onChange={handleChange}
                
                ></input>
        </form>
       
    )
}

export default SearchForm