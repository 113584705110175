import React, {useContext} from 'react'
import classes from '../styles/MeetingCard.module.css'
import LanguageContext from '../store/LanguageContext';
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faCalendar, faLocationDot} from '@fortawesome/free-solid-svg-icons';
import { ColorPicker } from '../utils/ColorPicker';
import MeetingTypeBadge from './MeetingTypeBadge';


const MeetingCardContent = (props) => {


    return (

        <Card 
            className={`${props.isLink ? classes.customCardLink : classes.customCard}`}
            style={{borderLeftColor: props.item.timeframe === 'today' || props.item.timeframe === 'future'? ColorPicker(props.item.type).dark :'',
                    backgroundColor: props.item.timeframe === 'today' ? ColorPicker(props.item.type).light :''}}    
            >
            <Card.Body >
                <Card.Title>
                    <MeetingTypeBadge typeName={props.item.type} />
                    <p 
                        style={{color: props.item.timeframe === 'today' || props.item.timeframe === 'future'? ColorPicker(props.item.type).dark :''}} 
                        className={classes.title}
                        >{props.item.title}</p>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                    <p>
                    <FontAwesomeIcon icon={faCalendar} className='fontAwesomeIcon'/>{props.item.dateDisplay}&nbsp; 
                    <FontAwesomeIcon icon={faClock} className='fontAwesomeIcon'/>{props.item.startTimeDisplay}-{props.item.endTimeDisplay} 
                    </p>
                    {
                        props.item.location && 
                        <p>
                            <FontAwesomeIcon icon={faLocationDot} className='fontAwesomeIcon'/>
                            {props.item.location}
                        </p>                     
                    }  
                    {
                        props.item.room && 
                        <p>
                            {props.item.room}
                        </p>                     
                    }                
                </Card.Subtitle>
            </Card.Body>
        </Card>
    );
};

const MeetingCard = (props) => {
    const ctx = useContext(LanguageContext);

    return (
            <>
            {props.isLink &&
                /* --- NOT USED - PAGINATED VERSION ----
                <Link className={classes.cardLink} to={`/meetings/${props.item.id}/${props.item.type}/${props.item.title}`} state={{meeting: props.item, currentPage: props.currentPage}}>
                */
                <Link className={classes.cardLink} to={`/meetings/${props.item.id}/${props.item.type}/${props.item.title}`} state={{meeting: props.item}}>
                    <MeetingCardContent item={props.item} isLink={true}/>
                </Link>
                }
            {!props.isLink &&
                    <MeetingCardContent item={props.item} isLink={false}/>
                }    
            </>


        
    )
}

export default MeetingCard