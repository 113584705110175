import React, {useContext} from 'react'
import classes from '../styles/AgendaPointsList.module.css'
import LanguageContext from '../store/LanguageContext';
import LoadingSpinner from "../utils/LoadingSpinner";
import AttachmentCounter from './AttachmentCounter';

const AgendaPointsList = (props) => {
    const ctx = useContext(LanguageContext);
  
     //--------------------------------------------------------------------------
     if (props.isLoading) 
     return (
         <div className="row centered">
             <LoadingSpinner />
         </div>
         );
     //--------------------------------------------------------------------------
     if (props.error) 
     {
         console.log("Error loading agenda points!");
         console.error(props.error);
         const errorMessageFR="Une erreur s'est produite lors du prêt des points à l'ordre du jour.";
         const errorMessageNL="Er is een fout opgetreden bij het uitlenen van agendapunten.";
         return (
             <div className="row centered">
                 <p>{ctx.language === 'FR' ? errorMessageFR : errorMessageNL}</p>
             </div>
             );
     } 
     //--------------------------------------------------------------------------
     if (props.list.length===0) 
     return (
         <div className="row centered">
             <p>{ctx.language === 'FR' ? "Aucun point à l'ordre du jour." : "Geen agendapunten."}</p>
         </div>
         );  
    //====================================================================================================== 

    return (
        <div className='container'>
            <div className='row gridHeaderRow'>
                <div className='col-1'>
                    <span>Nr</span>
                </div>
                <div className='col-6'>
                    <span>{ctx.language === 'FR' ? "Ordre du jour" : "Agendapunten"}</span>
                </div>
                <div className='col-2'>
                    <span>{ctx.language === 'FR' ? "Propriétaire" : "Eigenaar"}</span>
                </div>
                <div className='col-2'>
                    <span>{ctx.language === 'FR' ? "Catégorie" : "Categorie"}</span>
                </div>
                <div className='col-1'>
                    <span>{ctx.language === 'FR' ? "Pièces jointes" : "Bijlagen"}</span>
                </div>
            </div>
            {props.list.map((item) => (
            <div className='row gridItemRow' key={item.id}>
                <div className='col-1'>
                    <span className={item.order<= 9 ? classes.orderBadgeSmall : classes.orderBadgeLarge}>{item.order}</span>
                </div>
                <div className='col-6'>
                    <button type="button" className={classes.link} onClick={()=> props.onSelect(item)}>{ctx.language === 'FR' ? item.subjectFR : item.subjectNL}</button>
                </div>
                <div className='col-2'>
                    <span>{item.owner}</span>
                </div>
                <div className='col-2'>
                    <span>{props.onSplitCategory(item.category, ctx.language)}</span>
                </div>
                <div className='col-1'>
                    <AttachmentCounter counter={item.itemChildCount} />
                </div>
            </div>
            ))}
        </div>
    )
}

export default AgendaPointsList