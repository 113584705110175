import React , {useState, useContext, useEffect} from 'react'
import { useIsAuthenticated, useMsal} from '@azure/msal-react';
import { loginRequest } from '../store/authConfig';
import { callMsGraph } from '../store/calls';
import {useNavigate} from 'react-router-dom';
import LanguageContext from '../store/LanguageContext';
import logo_fr from './../images/Logo_FR.png';
import logo_nl from './../images/Logo_NL.png';
import classes from '../styles/Header.module.css'
import LanguageSwitcher from '../components/LanguageSwitcher';
import SearchForm from '../components/SearchForm';
import {UserProfile} from '../components/UserProfile';
import Cookies from 'js-cookie';

const Header = (props) => {
    const { instance, accounts } = useMsal();
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const ctx = useContext(LanguageContext);

    const [userProfile, setUserProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //--------------------------------------------
    const RedirectToSignInPage = () =>{
        sessionStorage.clear();
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        
    }
    //-----------------------------------------------------------------------------------------------------------
    async function RequestProfileData(accessToken){
        setIsLoading(true);
        try
        {
            const response = await callMsGraph(accessToken);
            const data = await response.json();
           
            setUserProfile(data);

            const lang = Cookies.get('preferredLanguage');
            if ((lang === null || lang === undefined) && data.preferredLanguage !== null)
            {
                Cookies.set('preferredLanguage', data.preferredLanguage.includes('fr') ? 'FR' : 'NL');
            }

            setIsLoading(false);
            setError(null);
        }
        catch(error)
        {
            console.log("Error loading profile data:", error);
            setIsLoading(false);
            setError(error);
        }

    }
    //-------------------------------------------------------------------
    useEffect(() => {
        console.log("load header. isAuthenticated=", isAuthenticated);
        if (isAuthenticated)
        {
            instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                RequestProfileData(response.accessToken);
            })
            .catch ((error) => { 
                console.log("There was an error getting access token!", error);
                RedirectToSignInPage();
            });  
        }

    }, [isAuthenticated]);
    //-------------------------------------
    const handleClick = () =>{
        navigate('/');  
    }

    //=====================================
    return (
        <header>  
            <nav className={`navbar  ${classes.header}`}>
                <div className={`col-sm-6 col-md-3 ${classes.headerLeft}`}>
                    <img src={ctx.language === 'FR' ? logo_fr : logo_nl} alt="" className={classes.logoLink} onClick={handleClick} />
                </div>
                
                <div className={`col-sm-6 col-md-9 ${classes.headerRight}`}>
                {!props.displayJustLogo === true &&
                    <>
                    
                    {isAuthenticated && 
                        <>
                            <SearchForm />
                            &nbsp; 
                            <UserProfile user={userProfile} isLoading={isLoading}/> 
                        </>
                    } 
                    &nbsp; 
                    <LanguageSwitcher/>
                    </>
                }
                </div>
            
            </nav>    
        </header>
    )
       
}

export default Header