import React, {useContext, useState, useEffect} from "react";
import LanguageContext from '../store/LanguageContext';
import { useMsal} from '@azure/msal-react';
import {useLocation, useNavigate} from 'react-router-dom';
import { callSearch} from '../store/calls';
import { loginRequest } from '../store/authConfig';
import LoadingSpinner from "../utils/LoadingSpinner";
import SearchResultCard from "../components/SearchResultCard";
import { DocumentPopup } from "../components/DocumentPopup";

const SearchResultPage = () => {
    const ctx = useContext(LanguageContext);
    const location = useLocation();
    const { instance, accounts } = useMsal();

    const [locationState, setLocationState] = useState({
        query:""
    });

    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);

    //--------------------------------------------
    const RedirectToSignInPage = () =>{
        sessionStorage.clear();
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        
    }
    //-----------------------------------
    const handleOpenModal = (item) => {
        setOpenModal(true);
        setSelectedDocument(item);

    }

    //-----------------------------------------------------------------
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedDocument(null);

    }

    //--- PAGE LOADING ------------------------------------------------------------------------------------------
    useEffect(()=> {
        if (location.state)
        {
           
            let _state = location.state;
            setLocationState(_state);

            instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                //call af to load results
                LoadSearchResults(response.accessToken, _state.query);
            })
            .catch ((error) => { 
                console.log("There was an error getting access token!", error);
                RedirectToSignInPage();
            });    
        }


    }, [location]);
    //-----------------------------------------------------------------------------------------------------------
    async function LoadSearchResults(accessToken, query){
        setIsLoading(true);
        try
        {
            const response = await callSearch(accessToken, query);
            if (!response.ok) {  
                throw new Error('Bad Request Error!');
            }
            const data = await response.json();
            setSearchResults(data);
            setIsLoading(false);
            setError(null);
        }
        catch(error)
        {
            console.log("Error loading search results:", error);
            setIsLoading(false);
            setError(error);
        }
    
    }
    //--------------------------------------------------------------------------
    if (isLoading) 
    return (
        <div className="row centered">
            <LoadingSpinner />
        </div>
        );
    //--------------------------------------------------------------------------
    if (error) 
    return (
        <div className="row centered">
            <p>{ctx.language === 'FR' ? "Une erreur s'est produite lors du chargement des résultats de la recherche." : "Er is een fout opgetreden bij het laden van zoekresultaten."}</p>
        </div>
        );
    //--------------------------------------------------------------------------
    if (locationState.query==="") 
    return (
        <div className="row centered">
            <h1>{ctx.language === 'FR' ? "Ajoutez un texte dans le formulaire de recherche." : "Voeg een tekst toe in het zoekformulier."}</h1>
        </div>
        );
    //=======================================================

    return(
        <div className="container">
            <h1>{ctx.language === 'FR' ? "Rechercher:" : "Zoeken naar:"}&nbsp;{locationState.query}</h1>
            {searchResults.length === 0 && 
                <p>{ctx.language === 'FR' ? "Aucun résultat trouvé!" : "Geen resultaten gevonden!"}</p>
            }

            {openModal &&
                <DocumentPopup 
                    documentName={selectedDocument.title} 
                    previewUrl={selectedDocument.serverRedirectedEmbedURL}
                    sharePointUrl={selectedDocument.sharePointPreviewURL}
                    downloadUrl={selectedDocument.downloadUrl}
                    onClose={handleCloseModal} 
                />
            }   

            <ul>
                {searchResults.map((item) => (
                <li key={`${item.uniqueId}`}>
                    <SearchResultCard item={item} onPreview={()=> handleOpenModal(item)}/>
                </li>
                ))}
            </ul>
            <br/>
        </div>        
    )
}

export default SearchResultPage;