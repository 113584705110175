import React, {useContext, useState} from 'react'
import classes from '../styles/DocumentList.module.css'
import LanguageContext from '../store/LanguageContext';
import UserContext from '../store/UserContext';
import {saveAs} from 'file-saver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFile, faFilePdf, faFileWord, faFileExcel, faFolderOpen, faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from "../utils/LoadingSpinner";
import { DocumentPopup } from './DocumentPopup';
import DocumentIcon from './DocumentIcon';

const DocumentList = (props) => {
    const langCtx = useContext(LanguageContext);
    
    const [openModal, setOpenModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
   
    const handleOpenModal = (item) => {
        //open popup to view doc

        setOpenModal(true);
        setSelectedDocument(item);

    }

    //-----------------------------------------------------------------
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedDocument(null);

    }

  //--------------------------------------------------------------------
  const handleDownload = (downloadUrl, name) => {
    //open popup to view doc
    try 
    {
        var isFileSaverSupported = !!new Blob();
        if (isFileSaverSupported)
        {
            //console.log("start image donwload");
            saveAs(downloadUrl,name);

        }
        else
        {
            console.log("File download is not supported by browser!");
            const downloadErrorMessageFR="Le téléchargement de fichier n'est pas encore pris en charge par le navigateur!";
            const downloadErrorMessageNL="Het downloaden van bestanden wordt nog niet ondersteund door de browser!";
            //display a modal with message!
        }
    } 
    catch (e) 
    {
        console.log(e);
    }
}
    
    //--------------------------------------------------------------------------
    if (props.isLoading) 
    return (
        <div className="row centered" style={{ paddingLeft: '20px' }}>
            <LoadingSpinner />
        </div>
        );
    //--------------------------------------------------------------------------
    if (props.error) 
	{
		//console.log("Error loading documents!");
		//console.error(props.error);
		const errorMessageFR="Une erreur s'est produite lors du prêt des documentes.";
		const errorMessageNL="Er is een fout opgetreden bij het uitlenen van documenten.";
		return (
            <div className="row centered">
                <p>{langCtx.language === 'FR' ? errorMessageFR : errorMessageNL}</p>
            </div>
			);
	} 
    //--------------------------------------------------------------------------
    /*
    if (props.list.length === 0) 
    return (

            <p>{props.emptyListMessage}</p>
        );
*/
    //====================================================================
    return (
        <>
             {openModal &&
                <DocumentPopup 
                    documentName={selectedDocument.name} 
                    previewUrl={selectedDocument.previewUrl}
                    sharePointUrl={selectedDocument.sharePointPreviewUrl}
                    downloadUrl={selectedDocument.downloadUrl}
                    onClose={handleCloseModal} 
                    />
            }

        <div className='container'>
            <div className='row gridHeaderRow'>
                <div className='col-1'>
                    <DocumentIcon fileType="file" />
                </div>
                <div className='col-5'>
                    <span>{props.documentTypeHeader ? props.documentTypeHeader : "Document"}</span>
                </div>
                <div className='col-2'>
                    <span>{langCtx.language === 'FR' ? "Statut" : "Status"}</span>
                </div>
                <div className='col-2'>
                    <span>{langCtx.language === 'FR' ? "Propriétaire" : "Eigenaar"}</span>
                </div>
                <div className='col-2'>
                    <span>{langCtx.language === 'FR' ? "Télécharger" : "Downloaden"}</span>
                </div>
            </div>
            {props.list.length === 0 &&
             <div className="row centered">
                <p><FontAwesomeIcon icon={faFolderOpen} className={`fontAwesomeIcon ${classes.folderIcon}`}/></p>
                <p>{langCtx.language === 'FR' ? "Ce dossier est vide." : "Deze map is leeg."}</p>
            </div>
            }
            {props.list.map((item) => (
            <div className='row gridItemRow' key={item.id}>
                <div className='col-1'>
                    <DocumentIcon fileType={item.icon} />
                </div>
                <div className='col-5'>
                    <button type="button" className={classes.link} onClick={()=> handleOpenModal(item)}>{item.name}</button>
                </div>
                <div className='col-2'>
                    {item.approvalStatus === '0' && <span className={`${classes.statusBadge} ${classes.statusApproved}`}>Approved</span>}
                    {item.approvalStatus === '1' && <span className={`${classes.statusBadge} ${classes.statusRejected}`}>Rejected</span>}
                    {item.approvalStatus === '2' && <span className={`${classes.statusBadge} ${classes.statusPending}`}>Pending</span>}
                    {item.approvalStatus === '3' && <span className={`${classes.statusBadge} ${classes.statusDraft}`}>Draft</span>}                    
                </div>
                <div className='col-2'>
                    <span>{item.owner}</span>
                </div>

                <div className='col-2'>
                    <button type="button" className={classes.link} onClick={()=> handleDownload(item.downloadUrl, item.name)} title="Download">
                        <FontAwesomeIcon icon={faDownload} className='fontAwesomeIcon'/>
                    </button>
                </div>
            </div>
            ))}
        </div>

        </>
    )
}

export default DocumentList