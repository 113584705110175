import React, {useContext, useState, useEffect} from 'react';
import { useMsal} from '@azure/msal-react';
import { loginRequest } from '../store/authConfig';
import LanguageContext from '../store/LanguageContext';
import AgendaPointCard from './AgendaPointCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { callGetAgendaPointDocuments } from '../store/calls';
import DocumentList from './DocumentList';

const AgendaPointDetailsView = (props) => {
    const ctx = useContext(LanguageContext);
    const { instance, accounts } = useMsal();
    
    const [documents, setDocuments] = useState([]);
    const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
    const [errorLoadingDocuments, setErrorLoadingDocuments] = useState(null);

    const backButtonFR = "Aperçu des points à l'ordre du jour";//Previous
    const backButtonNL = "Overzicht agendapunten";

    //--------------------------------------------
    const RedirectToSignInPage = () =>{
        sessionStorage.clear();
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        
    }
    //-----------------------------------------------------------------------------------------
    useEffect(()=> {
        console.log("Load agenda point detail view, props=", props);
                if (props.meeting)
                {
                    instance
                    .acquireTokenSilent({
                        ...loginRequest,
                        account: accounts[0],
                    })
                    .then((response) => {
                        LoadAgendaPointsDocuments(response.accessToken, props.meeting.id, props.meeting.type, props.item.uniqueId);
                    })
                    .catch ((error) => { 
                        console.log("There was an error getting access token!", error);
                        RedirectToSignInPage();
                    });  
                }
            }, []);
               
    //----------------------------------------------------------------------------------------------
    async function LoadAgendaPointsDocuments(accessToken, id, type, uniqueId){
        setIsLoadingDocuments(true);
        try
        {
            const response = await callGetAgendaPointDocuments(accessToken, id, type,uniqueId);
            const data = await response.json();
            setDocuments(data);
            setIsLoadingDocuments(false);
            setErrorLoadingDocuments(null);
        }
        catch(error)
        {
            console.log("Error loading agenda points documents list:", error);
            setIsLoadingDocuments(false);
            setErrorLoadingDocuments(error);
        }
       
    }


    //==================================================================================
    

    return (

        <div className='container'>
            <div className='row'>
                <div className='col-sm-12 col-md-6'>
                    <span onClick={props.onClickBack} className='backButton'>
                        <FontAwesomeIcon icon={faArrowLeft} className='fontAwesomeIcon'/>{ctx.language === 'FR' ? backButtonFR : backButtonNL}
                    </span>
                </div>
            </div>    
            <div className='row'>
                <div className='col-sm-12 col-md-6'>
                    <AgendaPointCard 
                        item={props.item} 
                        itemCategory={props.onSplitCategory(props.item.category, ctx.language)}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                        <DocumentList 
                            list={documents} 
                            isLoading={isLoadingDocuments}
                            error={errorLoadingDocuments}
                            documentTypeHeader={ctx.language === 'FR' ? "Annexe" : "Bijlage"}
                            emptyListMessage={ctx.language === 'FR' ? "Ce point de l'ordre du jour n'a pas de pièces jointes." : "Dit agendapunt heeft geen bijlagen."}
                        /> 
                        {
                            !isLoadingDocuments && props.item.itemChildCount > 0  && props.item.itemChildCount > documents.length &&
                            <p>{ctx.language === 'FR' ? "Il peut y avoir d'autres pièces jointes que vous n'êtes pas autorisé à afficher." : "Er kunnen andere bijlagen zijn die u niet mag bekijken."}</p>
                        }
                        
                </div>
            </div>
        </div>        

    )
}

export default AgendaPointDetailsView