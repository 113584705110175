import Popover from 'react-bootstrap/Popover';
import classes from '../styles/CheckboxList.module.css'
import React, {useContext, useState, useEffect} from 'react'
import LanguageContext from '../store/LanguageContext';
import { faCircleExclamation,faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from "../utils/LoadingSpinner";
import MeetingTypeBadge from './MeetingTypeBadge';



const CheckboxList = (props) => {
  const ctx = useContext(LanguageContext);

  const catalog = props.list.map(({ name, titleFR, titleNL, descriptionFR, descriptionNL }) => {
    return (
      <li key={name}>
      <input
        id={name}
        name={name}
        type="checkbox"
        onChange={props.onCheck}
        checked={props.selectedList.includes(name)}
      />&nbsp;

        <MeetingTypeBadge typeName={name} typeTitle={ctx.language === 'FR' ? titleFR : titleNL} size='large' />
        
        <button className={`${classes.infoButton}`} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <FontAwesomeIcon icon={faCircleQuestion}/>
            </button>
            <div className={`${classes.infoTooltip} dropdown-menu`} aria-labelledby="dropdownMenuButton">
                <span  style={{ fontWeight: 'bold' }}>{ctx.language === 'FR' ? titleFR : titleNL}</span><br/>
                {ctx.language === 'FR' ? descriptionFR : descriptionNL}
            </div>
      </li>
    );
  });

  //--------------------------------------------------------------------------
  if (props.isLoading) 
  return (
      <div className="row centered">
          <LoadingSpinner />
      </div>
      );
  //--------------------------------------------------------------------------

  const selectAllFR = "Voir tout";//View all
  const selectAllNL = "Alles bekijken";

  return (
    <div>
      <input
        id="selectAll"
        name="selectAll"
        type="checkbox"
        onChange={props.onSelectAll}
        checked={props.isCheckAll}
      />&nbsp;
      <span  style={{ fontWeight: 'bold' }}>{ctx.language === 'FR' ? selectAllFR : selectAllNL}</span>
      <ul>
      {catalog}
      </ul>
    </div>
  );

}//end

export default CheckboxList