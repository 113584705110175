import React, {useContext} from 'react'
import classes from '../styles/AgendaPointCard.module.css'
import LanguageContext from '../store/LanguageContext';
import Card from 'react-bootstrap/Card';
import AttachmentCounter from './AttachmentCounter';

const AgendaPointCard = (props) => {
    const ctx = useContext(LanguageContext);

    return (
        <Card 
        className={classes.customCard}
         
        >
        <Card.Body >
            <Card.Title>
                <span className={classes.badge}>{ctx.language === 'FR' ? "Ordre du jour" : "Agendapunten"}&nbsp;#{props.item.order} </span>
                <p className={classes.title}>{ctx.language === 'FR' ? props.item.subjectFR : props.item.subjectNL}</p>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
                <div className='row'>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        {ctx.language === 'FR' ? "Propriétaire" : "Eigenaar"}:
                    </div>
                    <div className='col-sm-6 col-md-8 col-lg-9'>
                        {props.item.owner}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        {ctx.language === 'FR' ? "Catégorie" : "Categorie"}:
                    </div>
                    <div className='col-sm-6 col-md-8 col-lg-9'>
                        {props.itemCategory}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        {ctx.language === 'FR' ? "Pièces jointes" : "Bijlagen"}:
                    </div>
                    <div className='col-sm-6 col-md-8 col-lg-9'>
                        <AttachmentCounter counter={props.item.itemChildCount} />
                    </div>
                </div>
    
            </Card.Subtitle>
        </Card.Body>
    </Card>


        
    )
}

export default AgendaPointCard