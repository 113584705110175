import React, {useContext} from 'react'
import classes from '../styles/MeetingTypeBadge.module.css'
import LanguageContext from '../store/LanguageContext';
import { ColorPicker } from '../utils/ColorPicker';

const MeetingTypeBadge = (props) => {
    const ctx = useContext(LanguageContext);

    return (
        <span 
            style={{backgroundColor: ColorPicker(props.typeName).dark}}
            className={` ${classes.badge} ${props.size==='large'? classes.large : ''}`}
        >{props.typeTitle ? props.typeTitle : props.typeName}  </span>
    )
}

export default MeetingTypeBadge