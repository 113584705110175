import colors from '../store/colors.json';

export function ColorPicker (meetingType) {
    const items = colors.filter(color => color.name===meetingType);
    if (items.length>0)
    {
        return {"dark":items[0].dark, "light":items[0].light} ;
    }
    else
    {
        return {"dark":"#989898", "light":"#ffffff"} ;
    }    
}