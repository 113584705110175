import React from "react";
import { useMsal } from "@azure/msal-react";
import classes from './../styles/UserProfile.module.css'
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from "../utils/LoadingSpinner";
import { callSignOut} from '../store/calls';
import { loginRequest } from '../store/authConfig';

export const UserProfile = (props) => {
    const { instance, accounts } = useMsal();

    const handleLogout = () => {

        instance
        .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        })
        .then((response) => {
            // call AF             
            callSignOut(response.accessToken)
            .then((resultAF) => {
                console.log("AF result", resultAF);
            })  
            .catch((error) => {
                console.log("There was an error doing logout!", error);
            })
   
        })
        .catch ((error) => {                 
            console.log("There was an error getting access token!", error);
        })
        .finally(() => {
            sessionStorage.clear();
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        });        
    }    

    //========================================================================
    return (
        <div className='dropdown'>
            <button className={`${classes.userProfileButton} `} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {(props.user === undefined || props.user === null) && <FontAwesomeIcon icon={faUser} className={classes.userIconButton}/>}

                {props.user !== undefined && props.user !== null && 
                <>
                    {props.user.picture !== null && <img src={props.user.picture} className={classes.userImgButton}/>}
                    {props.user.picture === null && <FontAwesomeIcon icon={faUser} className={classes.userIconButton}/>}
                </>
                }

            </button>
            <div className={`${classes.userProfileMenu} dropdown-menu`} aria-labelledby="dropdownMenuButton">
                {
                    (props.isLoading ) &&
                    <div className={`${classes.userProfile} centered`}>
                        <LoadingSpinner size="small"/>
                    </div>
                }
                {
                    props.user !== undefined && props.user !== null &&
                    <div className={`${classes.userProfile}`}>
                        <div className={classes.divUserName}>
                            {props.user.picture !== null && <img src={props.user.picture} className={classes.userImg}/>}
                            {props.user.picture === null && <span className={classes.userIcon}><FontAwesomeIcon icon={faUser}/></span>}
                            <span className={classes.userName}>
                            
                                {props.user.displayName}</span>
                        </div>
                        
                        <span>{props.user.mail}</span>
                        
                    </div>
                }
                
                <button className={`${classes.signOutButton} dropdown-item`} onClick={() => handleLogout()}>Sign out</button>
            </div>
        </div>       
    )
}