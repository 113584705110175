import React, {useContext, useCallback,useState,useEffect} from "react";
import LanguageContext from '../store/LanguageContext';
import { Calendar, momentLocalizer, DateLocalizer, Views } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {useLocation, useNavigate} from 'react-router-dom';
import classes from '../styles/MeetingsCalendar.module.css'
import LoadingSpinner from "../utils/LoadingSpinner";
import { ColorPicker } from '../utils/ColorPicker';

// and, for optional time zone support
import 'moment-timezone'
moment.tz.setDefault('Europe/Brussels')
// end optional time zone support

const MeetingsCalendar = (props) => {

    const ctx = useContext(LanguageContext);
    const navigate = useNavigate();
    const location = useLocation();
    /*
    const [locationState, setLocationState] = useState({
        item:"",
        status:"",
        from:"",
        view:""
    });
    */
    const localizer = momentLocalizer(moment)

    //console.log("moemnt local=",moment.locale());

    const [culture, setCulture] = useState('fr');

    const [view, setView] = useState(Views.MONTH);
    const [date, setDate] = useState(moment());
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [meetingMinDate, setMeetingMinDate] = useState(new Date());
    const [pastCurrentPage, setPastCurrentPage] = useState();
/*
const calendarEvents = props.allMeetings.map(item => ({
    id: item.id,
    title: item.title,
    type: item.type,
    start: new Date(item.startTime),
    end: new Date(item.endTime),
    dateDisplay: item.dateDisplay,
    startTimeDisplay: item.startTimeDisplay,
    endTimeDisplay: item.endTimeDisplay,
    location: item.location,
    room: item.room
    }));
*/
    const messages = {
        week: ctx.language === 'FR' ? 'Semaine' : 'Week',
        work_week: ctx.language === 'FR' ? 'Semaine de travail' : 'Werkweek',
        day: ctx.language === 'FR' ? 'Jour' : 'Dag',
        month: ctx.language === 'FR' ? 'Mois' : 'Maand',
        previous: ctx.language === 'FR' ? 'Précédent' : 'Vorig',
        next: ctx.language === 'FR' ? 'Suivant' : 'Volgende',
        today: ctx.language === 'FR' ? "Aujourd'hui" : 'Vandaag',
        agenda: ctx.language === 'FR' ? "Ordre du jour" : 'Agenda',
        showMore: (total) => `+${total} ${ctx.language === 'FR' ? "plus" : 'meer'}`,
        noEventsInRange: ctx.language === 'FR' ? "Il n'y a pas d'événements dans cette plage." : 'Er zijn geen evenementen in dit bereik.',
    }
    //--------------------------------------------------------------------
    /*
    useEffect(()=> {
        console.log("calendar, set current page =", props.pastCurrentPage);
        setPastCurrentPage(props.pastCurrentPage);

    }, [props.pastCurrentPage]);
    */
    //--------------------------------
   
    useEffect(()=> {
        console.log("set events!!", props.allMeetings);
        setCalendarEvents(props.allMeetings.map(item => ({
            id: item.id,
            title: item.title,
            type: item.type,
            start: new Date(item.startTime),
            end: new Date(item.endTime),
            dateDisplay: item.dateDisplay,
            startTimeDisplay: item.startTimeDisplay,
            endTimeDisplay: item.endTimeDisplay,
            location: item.location,
            room: item.room
            })));

        const newMinDate = new Date(
            Math.min(
                ...props.allMeetings.map(element => {
                return new Date(element.startTime);
                }),
            ),
            ); 

        setMeetingMinDate(newMinDate)

        console.log("minDate=",newMinDate);   

    }, [props.allMeetings]);
    
    //--------------------------------------------------------------------
    useEffect(()=> {
        console.log("CALENDAR location.state=",location.state);
        if (location.state)
        {
            let _state = location.state;
           // setLocationState(_state);
           
            if (_state.view && _state.view !== "")
            {
                setView(_state.view);
            }
            if (_state.defaultDate && _state.defaultDate !== "")
            {
                setDate(moment(_state.defaultDate));
            }
            if (_state.currentPage && _state.currentPage !== "")
            {
                setPastCurrentPage(_state.currentPage);
            }
        }

    }, [location]);
    //------------------------------------------------------
    /*
    useEffect(() => {
        if (props.currentPage && props.currentPage !=="")
            {
                setPastCurrentPage(props.currentPage);
            }
  
    }, [props.currentPage]);
    */
    //---------------------------------------------------------------
    function Event(props) {
        return (
            <>
                <span 
                    style={{backgroundColor: ColorPicker(props.event.type).dark}}
                    className={classes.badge} 
                    >{props.event.type} </span><br/>
                
                <span className={classes.title}>{props.title}</span>
            </>
            );
    }

    //---------------------------------------------------------------
    const eventPropGetter = useCallback(
        (event, start, end, isSelected) => ({
        ...({
            style: {
            backgroundColor:ColorPicker(event.type).light,
            color: ColorPicker(event.type).dark,
            borderColor:ColorPicker(event.type).dark,
             
            },
        }),
        ...({
            className: classes.customCard,
        }),
  
        }),
        []
    )
    //---------------------------------------------------------------
    const onSelectEvent = useCallback(
        (calEvent) => {
            console.log("pastCurrentPage before redirect=", pastCurrentPage);
            navigate(`/meetings/${calEvent.id}/${calEvent.type}/${calEvent.title}`,{ state: { meeting: calEvent, currentPage: pastCurrentPage, from:"calendar", view: view, defaultDate: date.toString()} });
        },
        [view, date]
        )

    //--------------------------------------

    const onNavigate = useCallback((newDate) => setDate(newDate), [setDate])
    const onView = useCallback((newView) => {setView(newView);}, [setView])

    const onRangeChange = useCallback((range) => {
        //console.log("++++++++++++++++++++++++++")
        console.log("range=", range)
        let calendarMin = new Date();
        if(range.start)
        {
            calendarMin = range.start;
        }
        else
        {
            calendarMin = range[0];
        }
   
        console.log("meetingMin=", meetingMinDate);
        console.log("calendarMin=", calendarMin);
        /*
        console.log("need older events (next page exists), minDate > calendarMin:", meetingMinDate > calendarMin && props.pastNextPage !== 0);
        console.log("props.pastNextPage=", props.pastNextPage);
        if (meetingMinDate > calendarMin && props.pastNextPage !== 0)
        {
            console.log("load more from calendar!");
            props.onPastLoadMore();
        }
        */
      }, [])

    //=============================================================================================

    if (props.isLoadingToday || props.isLoadingFuture || props.isLoadingPast) 
    return (
        <div className="row centered" >
            <LoadingSpinner />
        </div>
        );

    //====================================================================================
    return(
        <div>
            <div className="row">
                <h3>{ctx.language === 'FR' ? "Calendrier" : "Kalender"}</h3>
                <Calendar
                    culture={culture}
                    localizer={localizer}
                    /*defaultDate={moment().toDate()}*/
                    date={date}
                    events={calendarEvents}
                    eventPropGetter={eventPropGetter}
                    components={{
                        event: Event
                      }}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectEvent={onSelectEvent}
                    style={{ height: 600 }}
                    messages={messages}
                    
                    onNavigate={onNavigate}
                    onView={onView}
                    onRangeChange={onRangeChange}
                    view={view}
                    />
                

            </div>
            <br/><br/>
        </div>        
    )
}

export default MeetingsCalendar;