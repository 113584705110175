import React, {useContext} from 'react'
import LanguageContext from '../store/LanguageContext';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../store/authConfig";
import LotteryImg from './../images/Loterie-Nationale-Loterij-Lottery-draw 1.jpg';
import classes from '../styles/SignInPage.module.css'

const SignInPage = (props) => {
    const ctx = useContext(LanguageContext);
    const { instance } = useMsal();
    

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        } 
    }

    return (
       <div className='container'>
            <div className='row h-100 align-items-center centered'>
                <div className='col-md-6 no-float'>
                    <img src={LotteryImg} className={classes.image} alt=""></img>
                </div>
                <div className='col-md-6 no-float'>
                    <h5 className="card-title">
                        {ctx.language === 'FR' ? "Bienvenue à la Loterie Nationale" : "Welkom bij de Nationale Loterij"} 
                    </h5>
                    <p>{ctx.language === 'FR' ? "Cliquez sur le bouton ci-dessous pour vous connecter en utilisant votre compte de la loterie." : "Klik op de onderstaande knop om in te loggen met uw Lottery-account."}</p>
                    <button type="button" className={classes.signInButton}  onClick={() => handleLogin("redirect")}>
                        {ctx.language === 'FR' ? "Connexion" : "Inloggen"} 
                    </button>
                </div>
            </div>
         
       </div>
    )
}

export default SignInPage