import React, {useContext} from 'react'
import LanguageContext from '../store/LanguageContext';
import MeetingCard from './MeetingCard';
import LoadingSpinner from "../utils/LoadingSpinner";

const MeetingList = (props) => {
    const ctx = useContext(LanguageContext);

    //--------------------------------------------------------------------------
    /*
    if (props.isLoading) 
    return (
        <div className="row " style={{ paddingLeft: '100px' }}>
            <LoadingSpinner />
        </div>
        );
        */
    //--------------------------------------------------------------------------
    if (props.isLoaded && props.list.length === 0) 
    return (
            <p>{props.emptyListMessage}</p>
        );
    //======================================================================================
    return (
        <>
            <ul id={props.name}>
                {props.list.map((meeting) => (
                <li key={`${meeting.id}${meeting.type}`}>
                    <MeetingCard item={meeting} isLink={true} /*currentPage={props.currentPage}*//> 
                </li>
                ))}
            </ul>
            {
                props.isLoading &&
                <div className="row " style={{ paddingLeft: '100px' }}>
                    <LoadingSpinner />
                </div>
            }

        </>
    )
}

export default MeetingList