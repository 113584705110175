import { graphConfig } from "./authConfig";
import { API_URL, GRAPH_URL, ENV_NAME } from "./environments";

//--------------------------------------------------------------------------------------------------------
/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(GRAPH_URL, options)
       // .then(response => response.json())
       // .catch(error => console.log(error));
}
//--------------------------------------------------------------------------------------------------------
export function callGetMeetingsTypes(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetMeetingsType",parameters:{}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
        //.then(response => response.json())
        //.catch(error => console.log(error));
}
//--------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------
export async function callGetMeeting(accessToken, meetingId, meetingType) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetMeeting",parameters:{"id": meetingId, "type": meetingType}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
    .then(response => response)
       // .then(response => response.json())
       .catch(error => console.log("Error in CallGetMeeting=", error));
}
//--------------------------------------------------------------------------------------------------------
export function callGetMeetings(accessToken, timeframe) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetMeetings",parameters:{"timeframe": timeframe}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
       // .then(response => response.json())
       // .catch(error => console.log(error));
}
//--------------------------------------------------------------------------------------------------------
//-- THIS IS NOT USED ----------------------------------------------
/*
export function callGetMeetingsPaginated(accessToken, timeframe, previousLastDayLoaded) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetMeetings",parameters:{"timeframe": timeframe, "previousLastDayLoaded": previousLastDayLoaded}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
       // .then(response => response.json())
       // .catch(error => console.log(error));
}
*/
//--------------------------------------------------------------------------------------------------------
export async function callGetAgendaPoints(accessToken, meetingId, meetingType) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetAgendaPoints",parameters:{"id": meetingId, "type": meetingType}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
    //.then(response => response.json())
    //.catch(error => console.log("Error in calls, getAgendaPoints:", error));
}
//--------------------------------------------------------------------------------------------------------
export async function callGetAgendaPoint(accessToken, meetingId, meetingType, apId) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetAgendaPoint",parameters:{"id": meetingId, "type": meetingType, "apId": apId}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
}
//--------------------------------------------------------------------------------------------------------
export async function callGetOfficialDocuments(accessToken, meetingId, meetingType) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetOfficialDocuments",parameters:{"id": meetingId, "type": meetingType}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
       // .then(response => response.json())
      //  .catch(error => console.log(error));
}
//--------------------------------------------------------------------------------------------------------
export async function callGetAgendaPointDocuments(accessToken, meetingId, meetingType, agendaPointId) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetAgendaPointDocuments",parameters:{"id": meetingId, "type": meetingType, "apId" : agendaPointId}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
        //.then(response => response.json())
        //.catch(error => console.log(error));
}
//--------------------------------------------------------------------------------------------------------
export async function callGetAttendees(accessToken, meetingId, meetingType) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetAttendees",parameters:{"id": meetingId, "type": meetingType}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
        //.then(response => response.json())
        //.catch(error => console.log(error));
}
//--------------------------------------------------------------------------------------------------------
export async function callSearch(accessToken, query) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"SearchMeetings",parameters:{"term": query}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
        //.then(response => response.json())
        //.catch(error => console.log(error));
}
//--------------------------------------------------------------------------------------------------------
export async function callSignOut(accessToken, query) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"UserSignout",parameters:{}};

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(API_PARAMS)
    };

    return fetch(API_URL, options)
        //.then(response => response.json())
        //.catch(error => console.log(error));
}
//--------------------------------------------------------------------------------------------------------