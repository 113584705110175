import React, {useContext} from 'react'
import LanguageContext from '../store/LanguageContext';
import { useNavigate } from "react-router-dom";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

const ErrorPage = (props) => {
    const ctx = useContext(LanguageContext);
    const navigate = useNavigate();

    const handleClick = () => {
        //console.log("redirect root!");
        navigate('/dashboard/overview');
        window.location.reload();
       
    }

    const backButtonFR = "Retour à l'aperçu de toutes les reunions";
    const backButtonNL = "Terug naar Overzicht van alle vergaderingen";

    return (
        <>
            <Header displayJustLogo="true" />

            <div style={{textAlign:'center'}}>
                <br/><br/><br/><br/>
                <span className='errorIcon'><FontAwesomeIcon icon={faExclamationTriangle} className='fontAwesomeIcon'/></span>
                <h1>{ctx.language === 'FR' ?  "Oups, quelque chose s'est mal passé." : "Oeps! Er is iets misgegaan."}</h1>

                <span onClick={handleClick} className='backButton'>
                    {ctx.language === 'FR' ? backButtonFR : backButtonNL}
                </span>
            </div>
        
            <Footer />
        </>
        
    )
}

export default ErrorPage