import React, {useContext, useState} from "react";
import MeetingList from './MeetingList';
import LanguageContext from '../store/LanguageContext';
import classes from '../styles/MeetingOverview.module.css'

const MeetingsOverview = (props) => {
    const ctx = useContext(LanguageContext);
   
    const todayTitleFR = "Réunions aujourd'hui  ";//Today
    const todayTitleNL = "Vergaderingen vandaag";
    const todayMessageFR = "Pas de réunions aujourd'hui.";//No meetings today.
    const todayMessageNL = "Geen vergaderingen vandaag.";

    const upcomingTitleFR = "Réunions à venir";//Upcoming meetings
    const upcomingTitleNL = "Komende vergaderingen";
    const upcomingMessageFR = "Aucune réunion à venir.";//No upcoming meetings.
    const upcomingMessageNL = "Geen komende vergaderingen.";

    const previousTitleFR = "Réunions précédentes";//Previous meetings
    const previousTitleNL = "Eerdere bijeenkomsten";
    const previousMessageFR = "Pas de réunions précédentes.";//No previous meetings.
    const previousMessageNL = "Geen eerdere bijeenkomsten.";

    const [visibleCounter, setVisibleCounter] = useState(10);
    //-----------------------------------------------------------------------------
    const handlerLoadMore =() =>{
        setVisibleCounter(prev => prev + 10);
    }
 
    //================================================================================
    return(
        <div>          
            <div className="row">
                <div className='col-sm-12 col-md-6'>

                    <h3>{ctx.language === 'FR' ? todayTitleFR : todayTitleNL}</h3>
                    <MeetingList 
                        isLoading={props.isLoadingToday}
                        isLoaded={props.isLoadedToday}
                        list={props.todayMeetings} 
                        emptyListMessage={ctx.language === 'FR' ? todayMessageFR : todayMessageNL}
                    />
                 
                    <h3>{ctx.language === 'FR' ? upcomingTitleFR : upcomingTitleNL}</h3>
                     <MeetingList 
                            isLoading={props.isLoadingFuture}
                            isLoaded={props.isLoadedFuture}
                            list={props.futureMeetings} 
                            emptyListMessage={ctx.language === 'FR' ? upcomingMessageFR : upcomingMessageNL}
                        /> 
                </div>
                <div className='col-sm-12 col-md-6'>
                    <h3>{ctx.language === 'FR' ? previousTitleFR : previousTitleNL}</h3>
                    <MeetingList 
                            isLoading={props.isLoadingPast}
                            isLoaded={props.isLoadedPast}
                            /*list={props.pastMeetings} */
                            list={props.pastMeetings.slice(0, visibleCounter)} 
                            emptyListMessage={ctx.language === 'FR' ? previousMessageFR : previousMessageNL}
                            currentPage={props.pastCurrentPage}
                        /> 
                    {
                        /*!props.isLoadingPast && props.isLoadedPast && props.pastNextPage !== 0 && */
                        !props.isLoadingPast && props.isLoadedPast && visibleCounter < props.pastMeetings.length &&
                        <div className="centered">
                            <button 
                                className={classes.btnLoadMore} 
                                /*onClick={props.onPastLoadMore} */
                                onClick={handlerLoadMore}
                            >{ctx.language === 'FR' ? "Charger plus" : "Meer laden"}
                            </button><br/><br/>
                        </div>  
                    }
                     
                </div>
            </div>
            
        </div>        
    )
}

export default MeetingsOverview;