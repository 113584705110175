import React, {useContext, useEffect, useState} from 'react'
import classes from '../styles/Footer.module.css'
import LanguageContext from '../store/LanguageContext';
import AttendeeList from './AttendeeList';

const AttendeesView = (props) => {
    const ctx = useContext(LanguageContext);

    const [attendeesPresent, setAttendeesPresent] = useState([]);
    const [attendeesNotPresent, setAttendeesNotPresent] = useState([]);
    const [attendeesForeseen, setAttendeesForeseen] = useState([]);



//Status can be: Attended, Not attended, Foreseen.
//Attendee name is read from the following fields in this specific order: 1. replacedBy, 2. attendeeInternal, 3. attendeeExternal. If none of this fields are filled in, the attendee will be ignored!
    //-----------------------------------------------------------------------------------------
    useEffect(()=> {
        //console.log("attendees=", props.list);
        const tempPresent = props.list.filter((item) =>item.attendingStatus === 'Attended');
        setAttendeesPresent(tempPresent);
        //console.log("tempPresent=", tempPresent)

        const tempNotPresent = props.list.filter((item) =>item.attendingStatus === 'Not attended');
        setAttendeesNotPresent(tempNotPresent);
        //console.log("tempNotPresent=", tempNotPresent)

        const tempForeseen = props.list.filter((item) =>item.attendingStatus === 'Foreseen');
        setAttendeesForeseen(tempForeseen);
        //console.log("tempForseen=", tempForeseen)
    }, []);
    //-----------------------------------------------------------------------------------------

    if (props.error) 
    {
        const errorMessageFR="Une erreur s'est produite lors du prêt du liste de présence.";
        const errorMessageNL="Er is een fout opgetreden bij het uitlenen van aanwezigheidslijst."; 
        return (
            <div className="row centered">
                <p>{ctx.language === 'FR' ? errorMessageFR : errorMessageNL}</p>
            </div>
            );
    } 
    //--------------------------------------------------------------------------
    
    if (props.list.length===0) 
    return (
        <div className="row centered">
            <p>{ctx.language === 'FR' ? "Liste de présence non disponible." : "Aanwezigheidslijst niet beschikbaar."}</p>
        </div>
        ); 
    //========================================================================================
    

    return (
        <div className='row'>
            <div className='col-4'>
                <h5>{ctx.language === 'FR' ? "A participé" : "Bijgewoond"} ({attendeesPresent.length})</h5>
                <AttendeeList status="Attended" list={attendeesPresent}/>
            </div>
            <div className='col-4'>
                <h5>{ctx.language === 'FR' ? "Pas assisté" : "Niet aanwezig"} ({attendeesNotPresent.length})</h5>
                <AttendeeList status="Not attended" list={attendeesNotPresent}/>
            </div>
            <div className='col-4'>
                <h5>{ctx.language === 'FR' ? "Prévu" : "Voorzien"} ({attendeesForeseen.length})</h5>
                <AttendeeList status="Foreseen" list={attendeesForeseen}/>
            </div>
        </div>
    )
}

export default AttendeesView