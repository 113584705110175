import React, {useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFilePdf, faFileWord, faFileExcel, faFilePowerpoint} from '@fortawesome/free-solid-svg-icons';
import iconPdf from '../icons/pdf.svg';
import iconDocx from '../icons/docx.svg';

import iconPptx from '../icons/pptx.svg';
import iconXlsx from '../icons/xlsx.svg';
import iconFile from '../icons/file.png';
import iconFolder from '../icons/folder.svg';
import iconEmail from '../icons/email.svg';

export const Icon = (props) =>{
    return <img src={props.src} style={{width:30}} alt={props.fileType}/>
}

const DocumentIcon = (props) => {
    if (props.fileType==="pdf") return <Icon src={iconPdf} fileType={props.fileType} />;
    if (props.fileType==="docx") return <Icon src={iconDocx} fileType={props.fileType} />;

    if (props.fileType==="xlsx") return <Icon src={iconXlsx} fileType={props.fileType} />;
    if (props.fileType==="pptx") return <Icon src={iconPptx} fileType={props.fileType} />;
    if (props.fileType==="folder") return <Icon src={iconFolder} fileType={props.fileType} />;
    if (props.fileType==="msg") return <Icon src={iconEmail} fileType={props.fileType} />;
    

    return (
        <Icon src={iconFile} />
    )
}

export default DocumentIcon