import React, {useContext} from 'react'
import classes from '../styles/SearchResultCard.module.css'
import LanguageContext from '../store/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye} from '@fortawesome/free-solid-svg-icons';
import colors from './../store/colors.json';
import DocumentIcon from './DocumentIcon';
import MeetingTypeBadge from './MeetingTypeBadge'
import { ColorPicker } from '../utils/ColorPicker';

const getSpecificColor = (type) =>{
    const items = colors.filter(color => color.name===type);
    if (items.length>0)
    {
        return {"dark":items[0].dark, "light":items[0].light} ;
    }
    else
    {
        return {"dark":"#989898", "light":"#ffffff"} ;
    }    
}



const SearchResultCard = (props) => {
    const ctx = useContext(LanguageContext);
//========================================================================
//search result type CAN BE: meeting / agendapoint / agendapoint_document / official_document
    return (
        <div className={`container ${classes.customCard}`}>
            <div className='row'>
                <div className='col-sm-9 col-md-9 col-lg-10'>
                    <p>
                        {(props.item.type === "agendapoint_document" || props.item.type==="official_document") &&
                            <><DocumentIcon fileType={props.item.fileType} />&nbsp;</>
                        }
                        {(props.item.type === "agendapoint" || props.item.type==="meeting") &&
                            <><DocumentIcon fileType="folder" />&nbsp;</>
                        }
                        <span className={classes.title}>{props.item.title}</span>
                    </p> 
                    {props.item.type === "agendapoint" &&
                        <p>
                            {ctx.language === 'FR' ? props.item.agendaPointSubjectFR : props.item.agendaPointSubjectNL}
                        </p>
                    }           
           
                    {props.item.hitHighlightedSummary && 
                        <div className={classes.divSummary} dangerouslySetInnerHTML={{ __html: props.item.hitHighlightedSummary.replaceAll("c0", "strong").replaceAll("<ddd/>", "&#8230;") }} />                            
                    } 
             
                    {props.item.appLinktoMeeting &&
                        <p>
                            {props.item.meetingType && <MeetingTypeBadge typeName={props.item.meetingType} />}
                            <a href={props.item.appLinktoMeeting} 
                                className={classes.meetingLink} 
                                style={{color: ColorPicker(props.item.meetingType).dark }}>
                                    {props.item.meetingTitle}
                            </a> 
                        </p>
                    }

                    {props.item.appLinktoAgendaPoint &&
                        <p>
                            <span className={props.item.agendaPointOrder<= 9 ? classes.orderBadgeSmall : classes.orderBadgeLarge}>{props.item.agendaPointOrder}</span>
                            <a href={props.item.appLinktoAgendaPoint}
                            className={classes.agendapointLink}
                            >{props.item.agendaPointTitle}</a>
                        </p>        
                    }

                </div>
                <div className='col-sm-3 col-md-3 col-lg-2'>                  
                    
                    {props.item.pictureThumbnailURL &&
                        <button onClick={props.onPreview} className={classes.btnPreview}>
                            <img src={props.item.pictureThumbnailURL} className={classes.previewImg}/>
                        </button>
                    }         
                    {props.item.pictureThumbnailURL === null && props.item.serverRedirectedEmbedURL &&
                        <button onClick={props.onPreview} className={classes.btnPreview}>
                            <FontAwesomeIcon icon={faEye} className='fontAwesomeIcon'/>
                        </button>
                    }                    
                </div>
            </div>
            

        </div>


        
    )
}

export default SearchResultCard