import React, {useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import LanguageContext from '../store/LanguageContext';
import UserContext from '../store/UserContext';
import {saveAs} from 'file-saver'
import classes from './../styles/DocumentPopup.module.css';
import iconNoImage from '../icons/no-image.png';
import iconSharepoint from '../icons/sharepoint.png';

export const DocumentPopup = (props) => {

    const langCtx = useContext(LanguageContext);
    const userCtx = useContext(UserContext);
    const iframeHeight = Math.round((userCtx.screenWidth * 9)/16)-250;
    const iframeWidth = userCtx.screenWidth-200;


    //--------------------------------------------------------------------
    const handleDownload = (downloadUrl, name) => {
        //open popup to view doc
        try 
        {
            var isFileSaverSupported = !!new Blob();
            if (isFileSaverSupported)
            {
                //console.log("start image donwload");
                saveAs(downloadUrl,name);

            }
            else
            {
                console.log("File download is not supported by browser!");
                const downloadErrorMessageFR="Le téléchargement de fichier n'est pas encore pris en charge par le navigateur!";
                const downloadErrorMessageNL="Het downloaden van bestanden wordt nog niet ondersteund door de browser!";
                //display a modal with message!
            }
        } 
        catch (e) 
        {
            console.log(e);
        }
    }

    //========================================================================
    console.log("preview=", props.previewUrl);
    return (
        <div className={classes.modalWrap}>
            <a href={props.sharePointUrl} target='blank'  className={classes.btnSharePoint} title="Open in SharePoint">
            <img src={iconSharepoint} className={classes.iconSharePoint} alt="Open in SharePoint" /></a>
                    
            <FontAwesomeIcon icon={faDownload} className={classes.btnDownload} onClick={()=> handleDownload(props.downloadUrl, props.documentName)} title="Download" />
            <FontAwesomeIcon icon={faCircleXmark} className={classes.btnClose} onClick={props.onClose} title="Close" />
            <div style={{ paddingTop:20, height: iframeHeight, width: iframeWidth}}>
                {
                    (props.previewUrl !==null && props.previewUrl !== "") &&   
                    <iframe src={props.previewUrl} style={{ height: iframeHeight , width: iframeWidth}}  className={classes.iframe}></iframe>
                }
                {
                    (props.previewUrl === null || props.previewUrl === "" )&&
                    <div className={classes.divNoPreview} style={{ height: iframeHeight , width: iframeWidth}}>
                        <br/>
                        <img src={iconNoImage} style={{width:100}}/><br/>
                        <span>{langCtx.language === 'FR' ? "Aucun aperçu disponible!" : "Geen voorbeeld beschikbaar!"}</span></div>
                }
            </div>   
        </div>      
)
}