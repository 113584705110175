import React, {useContext} from 'react'
import classes from '../styles/AttachmentCounter.module.css'
import LanguageContext from '../store/LanguageContext';

const AttachmentCounter = (props) => {
    const ctx = useContext(LanguageContext);

    return (
        <>
        <span className={classes.attach}>
            <span className={props.counter<=9 ? classes.attachBadgeSmall : classes.attachBadgeLarge}>{props.counter}</span>
            &nbsp;&nbsp;&nbsp;
        </span>
        </>
    )
}

export default AttachmentCounter