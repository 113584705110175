import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css"; 
import "bootstrap";
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate} from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import LanguageContext from "./store/LanguageContext";
import UserContext from "./store/UserContext";
import { PageLayout } from './layout/PageLayout';
import SignInPage from './pages/SignInPage';
import DashboardPage from './pages/DashboardPage';
import PageNotFound from './pages/PageNotFound';
import './styles/App.css';
import MeetingDetailPage from './pages/MeetingDetailPage';
import SearchResultPage from './pages/SearchResultPage';
import Cookies from 'js-cookie';


const MainContent = () => {
    return (
        <>
            <AuthenticatedTemplate>
                    <Routes>
                        <Route path="/dashboard/calendar" element={<DashboardPage view="calendar" />} />
                        <Route path="/dashboard/overview" element={<DashboardPage view="overview"/>} />
                        <Route path="/meetings/:id/:type/:title" element={<MeetingDetailPage />} />
                        <Route path="/meetings/:id/:type/:title/:apId" element={<MeetingDetailPage />} />
                        <Route path="/search" element={<SearchResultPage />} />
                        <Route path='/dashboard' element={<Navigate to='/dashboard/overview' replace />} />
                        <Route path='/' element={<Navigate to='/dashboard/overview' replace />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <SignInPage />
            </UnauthenticatedTemplate>
        </>
    );
};

export default function App() {
    const [isSignedIn] = useState(false);//useIsSignedIn();
    const [language, setLanguage] = useState("NL");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    
    //-------------------------------------------------------------------
    useEffect(() => {
        const preferredLanguage = Cookies.get('preferredLanguage');
        if (preferredLanguage==="FR" || preferredLanguage==="NL")
        {
            setLanguage(preferredLanguage);
        }

        const handleResizeWindow = () => setScreenWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);

        return () => {
        // unsubscribe "onComponentDestroy"
        window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);
    //-------------------------------------------------

    const handleSwitchLanguage = (lang) =>{
        setLanguage(lang);
        Cookies.set('preferredLanguage', lang);
    }
    //==================================================

    return (
        <LanguageContext.Provider 
            value={{
                language: language,
                setLanguage: handleSwitchLanguage
            }}>
            <UserContext.Provider 
                value={{
                    isSignedIn: isSignedIn,
                    screenWidth: screenWidth
                }}>

                <PageLayout>
                    <MainContent />
                </PageLayout>
        
            </UserContext.Provider>
        </LanguageContext.Provider>
    );
}
