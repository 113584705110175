import React, {useContext} from "react";
import classes from '../styles/PageNotFound.module.css'
import LanguageContext from '../store/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const ctx = useContext(LanguageContext);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/dashboard/overview');
        window.location.reload();  
    }

    const errorMessageFR="Page non trouvée!";
    const errorMessageNL="Pagina niet gevonden!"

    const backButtonFR = "Retour à l'aperçu de toutes les reunions";
    const backButtonNL = "Terug naar Overzicht van alle vergaderingen";

    return(
        <div className={classes.container}> 
            <br/><br/><br/>
            <span className='errorIcon'><FontAwesomeIcon icon={faExclamationTriangle} className='fontAwesomeIcon'/></span>
            <h1>{ctx.language === 'FR' ? errorMessageFR : errorMessageNL}</h1>
            <span onClick={handleClick} className='backButton'>
                {ctx.language === 'FR' ? backButtonFR : backButtonNL}
            </span>
        </div>        
    )
}

export default PageNotFound;