import React, {useContext} from 'react'
import classes from '../styles/DashboardViewSwitcher.module.css'
import LanguageContext from '../store/LanguageContext';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListUl, faCalendarDays } from '@fortawesome/free-solid-svg-icons'

const DashboardViewSwitcher = (props) => {
    const ctx = useContext(LanguageContext);

    const calendarFR = "Calendrier";//Calendar
    const calendarNL = "Kalender";

    const overviewFR = "Aperçu";//Overview
    const overviewNL = "Overzicht";

    return (
        <>
        <NavLink to="/dashboard/overview" 
              className={({ isActive }) => {
                const linkClasses = [classes.navLink, classes.navLinkOverview];
                if (isActive) linkClasses.push(classes.activeNavLink);
                return linkClasses.join(" "); 
              }}
        >
            <FontAwesomeIcon icon={faListUl} className='fontAwesomeIcon'/>
            <span className="hidden-mobile">{ctx.language === 'FR' ? overviewFR : overviewNL}</span>
        </NavLink>

        <NavLink to="/dashboard/calendar" 
            className={({ isActive }) => {
                const linkClasses = [classes.navLink, classes.navLinkCalendar];
                if (isActive) linkClasses.push(classes.activeNavLink);
                return linkClasses.join(" "); 
            }}
        >
            <FontAwesomeIcon icon={faCalendarDays} className='fontAwesomeIcon'/>
            <span className="hidden-mobile">{ctx.language === 'FR' ? calendarFR : calendarNL}</span>
        </NavLink><br/><br/>
        </>
    )
}

export default DashboardViewSwitcher