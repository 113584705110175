import React, {useContext, useState, useEffect, useCallback} from 'react';
import { useMsal} from '@azure/msal-react';
import {useLocation, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faFile, faLayerGroup, faUserGroup} from '@fortawesome/free-solid-svg-icons';

import { callGetMeeting, callGetAgendaPoint, callGetAgendaPoints, callGetOfficialDocuments, callGetAttendees } from '../store/calls';
import { loginRequest } from '../store/authConfig';
import classes from '../styles/MeetingDetailPage.module.css'
import LanguageContext from '../store/LanguageContext';
import MeetingCard from '../components/MeetingCard';
import AgendaPointsList from '../components/AgendaPointsList';
import AgendaPointDetailsView from '../components/AgendaPointDetailsView';
import DocumentList from './../components/DocumentList';
import AttendeesView from '../components/AttendeesView';
import LoadingSpinner from "../utils/LoadingSpinner";
import { useParams } from 'react-router-dom';

const MeetingDetailPage = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const ctx = useContext(LanguageContext);
    const [activeTab, setActiveTab] = useState("Tab1");
    const [locationState, setLocationState] = useState({
        meeting:"",
        currentPage:"",
        agendaPoint:"",
        from:"",
        view:"",
        defaultDate:""
    });
    
    const [currentMeeting, setCurrentMeeting] = useState();
    const [isLoadingMeeting, setIsLoadingMeeting] = useState(false);
    const [errorLoadingMeeting, setErrorLoadingMeeting] = useState(null);   

    const [agendaPoints, setAgendaPoints] = useState([]);
    const [isLoadingAgendaPoints, setIsLoadingAgendaPoints] = useState(false);
    const [errorLoadingAgendaPoints, setErrorLoadingAgendaPoints] = useState(null);

    const [currentAgendaPoint, setCurrentAgendaPoint] = useState(null);
    const [isLoadingAP, setIsLoadingAP] = useState(false);
    const [errorLoadingAP, setErrorLoadingAP] = useState(null); 

    const [officialDocuments, setOfficialDocuments] = useState([]);
    const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
    const [errorLoadingDocuments, setErrorLoadingDocuments] = useState(null);
    
    const [attendees, setAttendees] = useState([]);
    const [isLoadingAttendees, setIsLoadingAttendees] = useState(false);
    const [errorLoadingAttendees, setErrorLoadingAttendees] = useState(null);

    const { id: meetingId, type: meetingType, title: meetingTitle, apId: agendaPointId} = useParams()

    //--------------------------------------------
    const RedirectToSignInPage = () =>{
        sessionStorage.clear();
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        
    }
   //--- PAGE LOADING ------------------------------------------------------------------------------------------
    useEffect(()=> {

        //console.log("start load meeting details!");
        if (location.state)
        {
           
            let _state = location.state;
            console.log("--> Load Meeting Details, _state=",_state);
            setLocationState(_state);
            setCurrentMeeting(_state.meeting);
            if (_state.agendaPoint)
            {
                //console.log("found agenda point=", _state.agendaPoint)
                setCurrentAgendaPoint(_state.agendaPoint);
            }
            else
            {
                setCurrentAgendaPoint(null);
            }
        }
        else
        {
            //console.log("--> Load Meeting Details,no state");
            //console.log("meetingId = ", meetingId);
            //console.log("agendaPointId = ", agendaPointId);

            instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                LoadMeeting(response.accessToken, meetingId, meetingType);
                if (agendaPointId)
                {
                    LoadAgendaPoint(response.accessToken, meetingId, meetingType,agendaPointId);
                }
            })
            .catch ((error) => { 
                console.log("There was an error getting access token!", error);
                RedirectToSignInPage();
            });    
        }


    }, [location,meetingId, meetingType,agendaPointId]);

    //---------------------------------------------------------
    useEffect(()=> {
        
        if (currentMeeting)
        {
            instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                LoadAgendaPoints(response.accessToken, meetingId, meetingType);
                LoadOfficialDocuments(response.accessToken, meetingId, meetingType);
                LoadAttendees(response.accessToken, meetingId, meetingType);
            })
            .catch ((error) => { 
                console.log("There was an error getting access token!", error);
                RedirectToSignInPage();
            });  
        }


    }, [currentMeeting]);
    //------------------------------------
    useEffect(()=> {
    
        setCurrentAgendaPoint(null);

    }, [activeTab]);
    //---- END PAGE LOADING --------------------------------------------------------------------------------


    //--- LOAD DATA -------------------------------------------------------------------------------------------
    async function LoadMeeting(accessToken, id, type){
        //console.log("make call to load one meeting, meetingId=", id);
        setIsLoadingMeeting(true);
        try
        {
            const response = await callGetMeeting(accessToken, id, type);
            if (!response.ok) {  
                throw new Error('Bad Request Error!');
            }    
            const data = await response.json();
            setCurrentMeeting(data);
            setIsLoadingMeeting(false);
            setErrorLoadingMeeting(null);
        }
        catch(error)
        {
            console.log("Error loading current meeting:", error);
            setIsLoadingMeeting(false);
            setErrorLoadingMeeting(error);
        }
    }
    //-----------------------------------------------------------------------------------------------------------
    async function LoadAgendaPoint(accessToken, id, type, apId){
        //console.log("make call to load one agenda point, apId=", apId);

        setIsLoadingAP(true);
        try
        {
            const response = await callGetAgendaPoint(accessToken, id, type, apId);
            if (!response.ok) {  
                throw new Error('Bad Request Error!');
            }
            const data = await response.json();
            setCurrentAgendaPoint(data);
            setIsLoadingAP(false);
            setErrorLoadingAP(null);
        }
        catch(error)
        {
            console.log("Error loading one specific agenda point:", error);
            setIsLoadingAP(false);
            setErrorLoadingAP(error);
        }
    
    }
    //-----------------------------------------------------------------------------------------------------------
    async function LoadAgendaPoints(accessToken, id, type){
        setIsLoadingAgendaPoints(true);
        try
        {
            //console.log("start callGetAgendaPoints!");
            const response = await callGetAgendaPoints(accessToken, id, type);
            if (!response.ok) {  
                throw new Error('Bad Request Error in callGetAgendaPoints!');
            }
            const data = await response.json();
            setAgendaPoints(data);
            setIsLoadingAgendaPoints(false);
            setErrorLoadingAgendaPoints(null);
        }
        catch(error)
        {
            console.log("Error loading agenda points list:", error);
            setIsLoadingAgendaPoints(false);
            setErrorLoadingAgendaPoints(error);
        }
       
    }
    //-----------------------------------------------------------------------------------------------------------
    async function LoadOfficialDocuments(accessToken, id, type){
        setIsLoadingDocuments(true);
        try
        {
            //console.log("start callGetOfficialDocuments!");
            const response = await callGetOfficialDocuments(accessToken, id, type);
            if (!response.ok) {  
                throw new Error('Bad Request Error in callGetOfficialDocuments!');
            }
            const data = await response.json();
            setOfficialDocuments(data);
            setIsLoadingDocuments(false);
            setErrorLoadingDocuments(null);
        }
        catch(error)
        {
            console.log("Error loading official documents list:", error);
            setIsLoadingDocuments(false);
            setErrorLoadingDocuments(error);
        }
    
    }
    //-----------------------------------------------------------------------------------------------------------
    async function LoadAttendees(accessToken, id, type){
        setIsLoadingAttendees(true);
        try
        {
            console.log("start callGetAttendees!");
            const response = await callGetAttendees(accessToken, id, type);
            if (!response.ok) {  
                throw new Error('Bad Request Error in callGetAttendees!');
            }
            const data = await response.json();
            setAttendees(data);
            setIsLoadingAttendees(false);
            setErrorLoadingAttendees(null);
        }
        catch(error)
        {
            console.log("Error loading attendees list:", error);
            setIsLoadingAttendees(false);
            setErrorLoadingAttendees(error);
        }
    
    }
    //--- END LOAD DATA --------------------------------------------------------------------------------------

    //--- EVENT HANDLERS -----------------------------------------------------------------------------------------
    const handleSelectAgendaPoint = (ap) =>{
        //console.log("agenda point link clicked, item=", ap);
   
        navigate(`/meetings/${currentMeeting.id}/${currentMeeting.type}/${currentMeeting.title}/${ap.uniqueId}`,{ state: { meeting: currentMeeting, currentPage: locationState.currentPage, agendaPoint: ap, from:locationState.from, view: locationState.view, defaultDate: locationState.defaultDate} }); 
    }
    //----------------------------------------------------------------
    const handleBackToList = () =>{
        //console.log("go back to agenda points list, for meeting=", currentMeeting);
        navigate(`/meetings/${currentMeeting.id}/${currentMeeting.type}/${currentMeeting.title}`,{ state: { meeting: currentMeeting, currentPage: locationState.currentPage, from:locationState.from, view: locationState.view, defaultDate: locationState.defaultDate} });
    }
   //------------------------------------------------------------------

    const handleSplitCategory = (category, lang) => {
        const categoryArray = category.split("/");
        if (categoryArray.length===2){
            if (lang==="FR"){
                return categoryArray[1];
            }
            else{
                return categoryArray[0];
            }
        }
        else{
            return "-";
        }     
    }
    // --- END EVENT HANDLERS -----------------------------------------------------------------------------------

    //----------------------------------------
    /*
    // listen history change and store it
    function useHistoryStack() {
        const [stack, setStack] = useState([]);
        const { pathname } = useLocation();
        const type = useNavigationType();
        useEffect(() => {
        if (type === "POP") {
            setStack(stack.slice(0, stack.length - 1));
        } else if (type === "PUSH") {
            setStack([...stack, pathname]);
        } else {
            setStack([...stack.slice(0, stack.length - 1), pathname]);
        }
        }, [pathname, type]);
    
        return stack;
    }
*/
    function handleBackButton () {
        /*const historyStack = useHistoryStack();
        if (historyStack.length > 0){
            console.log("GO BACK!!!");
            navigate(-1);  
        }
        */
       //console.log("locationState=", locationState)
       if (locationState.meeting && locationState.meeting !== "")
       {
            if (locationState.from === "calendar")
            {
                //console.log("go back calendar, view=",locationState.view);
                navigate('/dashboard/calendar',{ state: { meeting: locationState.meeting, currentPage: locationState.currentPage, view: locationState.view, defaultDate: locationState.defaultDate} }); 
            }
            else
            {
                console.log("navigate back to overview(has meeting)");
                navigate('/dashboard/overview',{ state: { currentPage: locationState.currentPage} }); 
            }
        }
        else
        {
            //navigate('/');  
            console.log("navigate back to overview");
            navigate('/dashboard/overview',{ state: { currentPage: locationState.currentPage} }); 
        }

        
    }
    //---------------------------------------------------------------------------------------------
    const backButtonFR = "Retour à l'aperçu de toutes les reunions";
    const backButtonNL = "Terug naar Overzicht van alle vergaderingen";

    const agendaPointsTitleFR = "Ordre du jour";//Agenda points
    const agendaPointsTitleNL = "Agendapunten";

    const officialDocumentsTitleFR = "Documents officiels";//Official documents
    const officialDocumentsTitleNL = "Officiële documenten";

    const attendeesTitleFR = "Participants";//Attendees
    const attendeesTitleNL = "Deelnemers";
    //=================================================================
    //--------------------------------------------------------------------------
    if (isLoadingMeeting) 
    return (
        <div className="row centered" >
            <br/><br/>
            <LoadingSpinner />
        </div>
        );
    //--------------------------------------------------------------------------
    if (errorLoadingMeeting || !currentMeeting) 
    {
        //console.log("Error loading current meeting!");
        //console.error(errorLoadingMeeting);
        const errorMessageFR="Une erreur s'est produite lors du prêt le réunion.";
        const errorMessageNL="Er is een fout opgetreden bij het uitlenen van vergadering.";
        return (
            <>
            <br/><br/>
            <h3>{ctx.language === 'FR' ? errorMessageFR : errorMessageNL}</h3>
            </>
                
            );
    } 
    //======================================================================================
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12 col-md-6'>
                    <span onClick={handleBackButton} className='backButton'>
                        <FontAwesomeIcon icon={faArrowLeft} className='fontAwesomeIcon'/>{ctx.language === 'FR' ? backButtonFR : backButtonNL}
                    </span>
                </div>
            </div>    
            <div className='row'>
                <div className='col-sm-12 col-md-6'>
                    <MeetingCard item={currentMeeting} isLink={false}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <nav>
                        <button 
                            onClick={()=>setActiveTab("Tab1")} 
                            className={`${classes.tab} ${activeTab === "Tab1" ? classes.tabActive : ''}`}
                        >
                            <FontAwesomeIcon icon={faLayerGroup} className='fontAwesomeIcon'/>
                            {ctx.language === 'FR' ? agendaPointsTitleFR : agendaPointsTitleNL}
                        </button>

                        <button 
                            onClick={()=>setActiveTab("Tab2")}
                            className={`${classes.tab} ${activeTab === "Tab2" ? classes.tabActive : ''}`}
                        >
                            <FontAwesomeIcon icon={faFile} className='fontAwesomeIcon'/>
                            {ctx.language === 'FR' ? officialDocumentsTitleFR : officialDocumentsTitleNL}
                        </button>

                        <button 
                            onClick={()=>setActiveTab("Tab3")}
                            className={`${classes.tab} ${activeTab === "Tab3" ? classes.tabActive : ''}`}
                        >
                            <FontAwesomeIcon icon={faUserGroup} className='fontAwesomeIcon'/>
                            {ctx.language === 'FR' ? attendeesTitleFR : attendeesTitleNL}
                        </button>
                    </nav>
                    <div className={classes.tabBody}>
                        {activeTab === "Tab1" && agendaPoints && currentAgendaPoint === null && 
                            <AgendaPointsList 
                                list={agendaPoints} 
                                isLoading={isLoadingAgendaPoints}
                                error={errorLoadingAgendaPoints}
                                onSelect={handleSelectAgendaPoint}
                                onSplitCategory={handleSplitCategory}
                            />
                        }
                        {activeTab === "Tab1" && agendaPoints && currentAgendaPoint  && 
                            <AgendaPointDetailsView 
                                meeting={currentMeeting}
                                item={currentAgendaPoint}
                                onClickBack={handleBackToList}
                                onSplitCategory={handleSplitCategory}
                            />
                        }
                        {activeTab === "Tab2" && officialDocuments &&
                            <DocumentList 
                                list={officialDocuments} 
                                isLoading={isLoadingDocuments}
                                error={errorLoadingDocuments}
                                documentTypeHeader={ctx.language === 'FR' ? "Document officiel" : "Officiële document"}
                            />
                        }
                        {activeTab === "Tab3" && attendees &&
                            <AttendeesView 
                                list={attendees} 
                                isLoading={isLoadingAttendees}
                                error={errorLoadingAttendees}
                            />
                        }
                        
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    )
}

export default MeetingDetailPage