import React, {useContext, useState, useEffect, setState,useCallback} from 'react'
import LanguageContext from '../store/LanguageContext';
import UserContext from '../store/UserContext';
import { loginRequest } from '../store/authConfig';
import { useIsAuthenticated, useMsal} from '@azure/msal-react';
import { callGetMeetingsTypes, callGetMeetings, callGetMeetingsPaginated } from '../store/calls';
import {useLocation, useNavigate} from 'react-router-dom';
import MeetingsOverview from '../components/MeetingsOverview';
import MeetingsCalendar from '../components/MeetingsCalendar';
import CheckboxList from '../components/CheckboxList';
import DashboardViewSwitcher from '../components/DashboardViewSwitcher';
import classes from '../styles/DashboardPage.module.css'
import LoadingSpinner from "../utils/LoadingSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

const DashboardPage = (props) => {
    const ctx = useContext(LanguageContext);
    const ctxUser = useContext(UserContext);

    const location = useLocation();
    const { instance, accounts } = useMsal();

    const [types, setTypes] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(true);
    const [selectedTypeList, setSelectedTypeList] = useState([]);
    const [isLoadingTypes, setIsLoadingTypes] = useState(false); 
    const [isLoadedTypes, setIsLoadedTypes] = useState(false); 
    const [errorLoadingTypes, setErrorLoadingTypes] = useState(null);

    const [todayMeetings, setTodayMeetings] = useState([]);
    const [isLoadingToday, setIsLoadingToday] = useState(false);
    //const [errorLoadingToday, setErrorLoadingToday] = useState(null);
    const [isLoadedToday, setIsLoadedToday] = useState(false);

    const [futureMeetings, setFutureMeetings] = useState([]);
    const [isLoadingFuture, setIsLoadingFuture] = useState(false);
    //const [errorLoadingFuture, setErrorLoadingFuture] = useState(null);
    const [isLoadedFuture, setIsLoadedFuture] = useState(false);

    const [pastMeetings, setPastMeetings] = useState([]);
    const [isLoadingPast, setIsLoadingPast] = useState(false);
    //const [errorLoadingPast, setErrorLoadingPast] = useState(null);
    const [isLoadedPast, setIsLoadedPast] = useState(false);
    const [pastMeetingsCurrentPage, setPastMeetingsCurrentPage] = useState(1);
    const [pastMeetingsHasMoreRecords, setPastMeetingsHasMoreRecords] = useState(false);

    const [errorLoadingMeetings, setErrorLoadingMeetings] = useState(null);

    const [filteredTodayMeetings, setFilteredTodayMeetings] = useState([]);
    const [filteredFutureMeetings, setFilteredFutureMeetings] = useState([]);
    const [filteredPastMeetings, setFilteredPastMeetings] = useState([]);

    const [isOverviewMode, setIsOverviewMode] = useState(props.view==="overview");

    //--------------------------------------------
    const RedirectToSignInPage = () =>{
        sessionStorage.clear();
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        
    }
    //--------------------------------------------
    async function LoadMeetings_TodayFuture(accessToken){
        setIsLoadingToday(true);
        setIsLoadingFuture(true);
        setIsLoadingPast(true);//set isLoading on true for past meetings - to display spinner
        try
        {
            const todayResponse = await callGetMeetings(accessToken, "today");
            if (!todayResponse.ok) {  
                throw new Error('Bad Request Error!');
            }
            const todayData = await todayResponse.json();
            setTodayMeetings(todayData.meetings);
            setIsLoadingToday(false);
            setIsLoadedToday(true);

            const futureResponse = await callGetMeetings(accessToken, "future");
            if (!futureResponse.ok) {  
                throw new Error('Bad Request Error!');
            }
            const futureData = await futureResponse.json();
            setFutureMeetings(futureData.meetings);
            setIsLoadingFuture(false);
            setIsLoadedFuture(true);


        }
        catch(error)
        {
            setIsLoadingToday(false);
            setIsLoadingFuture(false);
            setErrorLoadingMeetings(error);
        }
    }
    //----------------------------------------------------
    async function LoadMeetings_Past(accessToken){
        setIsLoadingPast(true);
        try
        {
            //--- this is the paginanted version -NOT USED! -------
            /*
            ..async function LoadMeetings_Past(accessToken, lastDay)...
            const data = await callGetMeetingsPaginated(accessToken, "previous", lastDay).then(response => response.json());
            setPastMeetings(prev => [...prev, ...data.meetings])
            */
            const pastResponse = await callGetMeetings(accessToken, "previous");
            if (!pastResponse.ok) {  
                throw new Error('Bad Request Error!');
            }
            const pastData = await pastResponse.json();
            setPastMeetings(pastData.meetings);

            setIsLoadingPast(false);
            setIsLoadedPast(true);

            setPastMeetingsHasMoreRecords(pastData.previousMeetingsAvailable);
        }
        catch(error)
        {
            console.log("Error loading previous meetings:", error)
            setIsLoadingPast(false);
            setErrorLoadingMeetings(error);
        }
    }

   
    //--------------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        //console.log("useEffect first time, load types")
         // Silently acquires an access token which is then attached to a request 
         instance
         .acquireTokenSilent({
             ...loginRequest,
             account: accounts[0],
         })
         .then((response) => {
            //---LOAD TYPES -----------------------------------------------
            var storedTypes = JSON.parse(sessionStorage.getItem('types'));
            var storedSelection = JSON.parse(sessionStorage.getItem('selectedTypeList'));
            if (storedTypes && storedTypes.length>0)
            {
                console.log("stored types found in sessions: types=", storedTypes);
                setTypes(storedTypes);
                setIsLoadedTypes(true);

                if (storedSelection !== null && storedSelection.length > 0) {
                    setSelectedTypeList(storedSelection);
                }
                else
                {
                    setSelectedTypeList(storedTypes.map(li => li.name));
                }
            }
            else
            {
                console.log("stored types NOT found in sessions!");
                //---CALL AF to load meeting types ------------------------------------
                (async () => {
                    console.log("call AF to load types!");
                    try
                    {          
                        setIsLoadingTypes(true);          
                        const dataResponse = await callGetMeetingsTypes(response.accessToken);
                        //console.log("callGetMeetingsTypes response=", dataResponse);
                        if (!dataResponse.ok) {  
                            console.log("status not OK");
                            throw new Error('Bad Request Error!');
                        }    
                        console.log("status OK");
                        const dataList = await dataResponse.json();   
                        //console.log("load types = ", dataList);      
                        setTypes(dataList);
                        sessionStorage.setItem("types", JSON.stringify(dataList));
                        setSelectedTypeList(dataList.map(li => li.name));
            
                        setIsLoadedTypes(true);
                        setIsLoadingTypes(false);
                        setErrorLoadingTypes(null);
                    }
                    catch(error)
                    {
                        setIsLoadingTypes(false);
                        setErrorLoadingTypes(error);
                        setIsLoadedTypes(true);
                    } 
                  })();
                //---------------------------------------------------------------------------
            }
        })
        .catch(error => {
            console.log(error);
            RedirectToSignInPage();
        });
    }, []);

    //----------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        //Load meetings after the meetings types are loaded!
        console.log("UseEffect wtih types, Types=", types);
        if (types.length>0)
        {    
            console.log("Use effect --->>>> Load meetings Today&Future!")
            // Silently acquires an access token which is then attached to a request
            instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                LoadMeetings_TodayFuture(response.accessToken);
            })
            .catch(error => {
                //error getting silent token
                console.log(error);
                RedirectToSignInPage();
            });
        }
    }, [types]);
    //----------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        //Load previous meetings after the today & future meetings are loaded!
        if (isLoadedToday)
        {    
            // Silently acquires an access token which is then attached to a request 
            instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {

                //--- NOT USED --- PAGINATED VERSION --
                /*
                console.log("LOAD PAST!")

                let page = 1;
                if (location.state)
                {
                    let _state = location.state;
                    console.log("Found state=", _state);
                    if (_state.currentPage && _state.currentPage !== "")
                    {
                        page = _state.currentPage;
                        setPastMeetingsCurrentPage(_state.currentPage);
                    }
                    
                }
                console.log("load pages: 1-", page);

                LoadMeetings_Past(response.accessToken,1, page);
                */
                LoadMeetings_Past(response.accessToken);
            })
            .catch(error => {
                //error getting silent token
                console.log(error);
                RedirectToSignInPage();
            });
        }
    }, [isLoadedToday]);

    //------------------------------------------------------------------------------------------
    useEffect(() => {
        setFilteredTodayMeetings(filterMeetingsByType(todayMeetings, selectedTypeList));
    }, [selectedTypeList,todayMeetings]);
    //----------------------------------------------------------------------------------
    useEffect(() => {
        setFilteredFutureMeetings(filterMeetingsByType(futureMeetings, selectedTypeList));
    }, [selectedTypeList,futureMeetings]);
    //---------------------------------------------------------------------------------
    useEffect(() => {
        setFilteredPastMeetings(filterMeetingsByType(pastMeetings, selectedTypeList));
    }, [selectedTypeList,pastMeetings]);
    //-------------------------------------------------------------------------------
    
    useEffect(() => {
        console.log("SWITCH VIEW!");
        if (props.view ==="overview")
            {
                setIsOverviewMode(true);
            }
        if (props.view ==="calendar")
            {
                setIsOverviewMode(false);
            }   
    }, [props.view]);
    //-------------------------------------------------------------   
    /*
    useEffect(()=> {
        console.log("state=", location.state);
        if (location.state)
        {
            let _state = location.state;
            console.log("Found state=", _state);
            if (_state.currentPage && _state.currentPage !== "")
            {
                setPastMeetingsCurrentPage(_state.currentPage);
            }
            
        }

    }, [location]);
*/
    //---------------------------------------------------------------------------------------------------------------------------------
    const filterMeetingsByType = (meetings, types) =>{
        return meetings.filter(meeting => {
            return types.some(t => t === meeting.type)
          })
    }
    //--------------------------------------------------
    const handleSelectAll = e => {
        let newSelected = [];
        if (e.target.checked) {
            newSelected = types.map(li => li.name);
        }

        setIsCheckAll(e.target.checked);
        setSelectedTypeList(newSelected);
        sessionStorage.setItem("selectedTypeList", JSON.stringify(newSelected)); 
    };
    //--------------------------------------------------
    const handleCheck = e => {
        const { id, checked } = e.target;
        let newSelected = [...selectedTypeList];
        if (checked)
        {
            // add new selected value
            newSelected.push(id);
        }
        else
        {
            // remove unselected value
            var index = newSelected.indexOf(id)
            if (index !== -1) {
                newSelected.splice(index, 1);
            }
        }

        setSelectedTypeList(newSelected);
        sessionStorage.setItem("selectedTypeList", JSON.stringify(newSelected)); 
        setIsCheckAll(false);
    };
    //-----------------------------------------------------------------------------
    //--- NOT USED --- PAGINATED VERSION --
    /*
    const handlerPastMeetingsLoadNextPage = () =>{
        console.log("-----------------------");
        console.log("load more was clicked!!");
        setPastMeetingsCurrentPage(pastMeetingsHasMoreRecords);
        // Silently acquires an access token which is then attached to a request 
        instance
        .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        })
        .then((response) => {
            //--- LOAD MEETINGS -------------------------------------
            console.log("load more: page ", pastMeetingsHasMoreRecords);
            LoadMeetings_Past(response.accessToken,pastMeetingsHasMoreRecords, pastMeetingsHasMoreRecords);

            //--------------------------------------------------------
        })
        .catch(error => {
            //error getting silent token
            console.log(error);
            RedirectToSignInPage();
        });       

    }

    //-----------------------------------------------------------------------------
    const handlerPastMeetingsLoadMorePages = () =>{
        //in calendar, we load 5 pages at a time.
        console.log("-----------------------");
        console.log("load more more page from calendar!!");
        let startPage = pastMeetingsHasMoreRecords;
        let endPage = pastMeetingsHasMoreRecords + 4
        setPastMeetingsCurrentPage(endPage);
        // Silently acquires an access token which is then attached to a request 
        instance
        .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        })
        .then((response) => {
            //--- LOAD MEETINGS -------------------------------------
            console.log("load more pages, from: ", startPage);
            console.log("to: ", endPage);
            LoadMeetings_Past(response.accessToken,startPage, endPage);

            //--------------------------------------------------------
        })
        .catch(error => {
            //error getting silent token
            console.log(error);
            RedirectToSignInPage();
        });       

    }

    */
    //==================================================================================================================
    const typesTitleFR = "Les réunions";//Meeting types
    const typesTitleNL = "De vergaderingen";

    const meetingOverviewTitleFR = "Aperçu des réunions";//Meeting overview
    const meetingOverviewTitleNL = "Overzicht van de vergaderingen";

    const errorMessageFR = "Il semble que vous ne disposiez pas des droits nécessaires pour afficher les sites de gestion. S'il s'agit d'une erreur, veuillez contacter : Stijn.DeBlauwe@nationale-loterij.be. Ensuite, nous ferons le nécessaire.";
    const errorMessageNL = "Het ziet ernaar uit dat u niet de nodige rechten hebt om de management sites te bekijken. Moest dit een vergissing zijn, gelieve dan contact op te nemen met: Stijn.DeBlauwe@nationale-loterij.be. Dan doen wij het nodige.";
    //==================================================================================================================
    //--------------------------------------------------------------------------
    if (isLoadingTypes) 
    return (
        <div className="row centered" >
            <br/><br/>
            <LoadingSpinner />
        </div>
        );
    //--------------------------------------------------------------------------
    return (
        <>
        {(errorLoadingTypes || (isLoadedTypes && types.length ===0)) &&
            <div className='container'>
                <div style={{textAlign:'center'}}>
                    <br/><br/><br/><br/>
                    <span className='errorIcon'><FontAwesomeIcon icon={faExclamationTriangle} className='fontAwesomeIcon'/></span>
                    <h1>{ctx.language === 'FR' ?  errorMessageFR : errorMessageNL}</h1> 
                </div>    
            </div>
        }

        {!errorLoadingTypes && types.length>0 &&
        <div className='container'>
            <div className={`${classes.customRow} row`}>
                <div className='col-sd-12 col-md-6' style={{ textAlign: 'left' }}>
                    <h2>{ctx.language === 'FR' ? meetingOverviewTitleFR : meetingOverviewTitleNL}</h2>
                </div> 
                <div className='col-sd-12 col-md-6' style={{ textAlign: 'right' }}>
                    <DashboardViewSwitcher />                      
                </div>   
            </div>
            <div className='row'>
                <div className='col-sm-12 col-md-3 col-lg-3 col-xl-2'>
                    <div className={`${classes.typesCard}`}>
                        <h5>{ctx.language === 'FR' ? typesTitleFR : typesTitleNL}</h5>
                        <CheckboxList 
                            isLoading={isLoadingTypes}
                            error={errorLoadingTypes}
                            list={types} 
                            selectedList={selectedTypeList} 
                            isCheckAll={isCheckAll} 
                            onCheck={handleCheck} 
                            onSelectAll={handleSelectAll}
                        />
                    </div>
                </div>
                <div className='col-sm-12 col-md-9 col-lg-9 col-xl-10' style={{ paddingLeft: '20px' }}>
                    {isLoadingTypes && 
                        <div className="centered ">
                            <LoadingSpinner />
                        </div>
                    }
                      
                    {errorLoadingMeetings &&
                        <div className="centered ">
                               <p>{ctx.language === 'FR' ? "Une erreur s'est produite lors du prêt des réunions." : "Er is een fout opgetreden bij het uitlenen van vergaderingen."}</p>
                        </div>       
                    } 

                    {errorLoadingMeetings ===null && !isLoadingTypes && isOverviewMode && 
                        <MeetingsOverview 
                            isLoading={isLoadingToday || isLoadingFuture || isLoadingPast}
                            isLoadingToday={isLoadingToday}
                            isLoadingFuture={isLoadingFuture} 
                            isLoadingPast={isLoadingPast}

                            isLoadedToday={isLoadedToday}
                            isLoadedFuture={isLoadedFuture}
                            isLoadedPast={isLoadedPast}

                            todayMeetings={filteredTodayMeetings}  
                            futureMeetings={filteredFutureMeetings} 
                            pastMeetings={filteredPastMeetings} 
                            /*
                            onPastLoadMore={handlerPastMeetingsLoadNextPage}
                            pastCurrentPage={pastMeetingsCurrentPage}
                            pastNextPage={pastMeetingsHasMoreRecords}
                            */
                        />}
                    {errorLoadingMeetings ===null && !isLoadingTypes && !isOverviewMode && 
                        <MeetingsCalendar 
                            isLoadingToday={isLoadingToday}
                            isLoadingFuture={isLoadingFuture} 
                            isLoadingPast={isLoadingPast}

                            todayMeetings={filteredTodayMeetings} 
                            futureMeetings={filteredFutureMeetings} 
                            pastMeetings={filteredPastMeetings} 

                            allMeetings={[...filteredPastMeetings,...filteredTodayMeetings, ...filteredFutureMeetings]}
                            /*
                            onPastLoadMore={handlerPastMeetingsLoadMorePages}
                            pastCurrentPage={pastMeetingsCurrentPage}
                            pastNextPage={pastMeetingsHasMoreRecords}
                            */
                        />}
                </div>
            </div>             
        </div>
        }
       </>
    )
}

export default DashboardPage