import React, {useContext} from 'react'
import LanguageContext from '../store/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faCircleQuestion, faCircleCheck} from '@fortawesome/free-solid-svg-icons';

const AttendeeList = (props) => {
    const ctx = useContext(LanguageContext);
//Status can be: Attended, Not attended, Foreseen.
//Attendee name is read from the following fields in this specific order: 1. replacedBy, 2. attendeeInternal, 3. attendeeExternal. If none of this fields are filled in, the attendee will be ignored!

    return (
        <>
        <ul id={props.status}>
            {props.list.map((item) => (
            <li key={`${item.id}`}>
                {props.status === 'Attended' && 
                    <FontAwesomeIcon icon={faCircleCheck} className='fontAwesomeIcon' style={{ color: '#4BE48C' }}/>
                }
                {props.status === 'Not attended' && 
                    <FontAwesomeIcon icon={faCircleXmark} className='fontAwesomeIcon' style={{ color: '#FF0000' }}/>
                }
                {props.status === 'Foreseen' && 
                    <FontAwesomeIcon icon={faCircleQuestion} className='fontAwesomeIcon' style={{ color: '#FDC300' }}/>
                }
                {item.replacedBy ? item.replacedBy : item.attendeeInternal ? item.attendeeInternal : item.attendeeExternal}
            </li>
            ))}
        </ul>
        </>
    )
}

export default AttendeeList